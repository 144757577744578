<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add a Synonym Set</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="modelSynonym.term"
                  required
                  label="Catalog Term"
                  ref="f_term"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="modelSynonym.synonym_type_id"
                  :items="synonym_types"
                  item-text="name"
                  item-value="key"
                  label="Typ nastavení synonyma"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-for="synonym in modelSynonym.synonyms" :key="synonym.id">
              <v-col cols="8">
                <v-text-field
                  v-model="synonym.name"
                  required
                  label="Name"
                  ref="f_synonyms"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="error dark large"
                  @click="removeValue(synonym.id)"
                  text
                  >Remove</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-btn @click="addValue" text>+ Add Value</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  REFRESH,
  POST_SYNONYMS,
  PULL_SYNONYMS
} from "@/core/services/store/synonyms.module";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    synonym_types: [
      { key: "1", name: "Equivalent synonyms (ipod, i-pod, i pod)" },
      {
        key: "2",
        name:
          "Synonyms entries are merged into catalog term (ipod, i-pod, i pod => ipod)"
      },
      {
        key: "3",
        name:
          "Synonyms entries are replaced by catalog term (foo => foo bar, baz)"
      }
    ]
  }),
  methods: {
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    addValue() {
      this.modelSynonym.synonyms.push({
        id: this.modelSynonym.synonyms.length + 1,
        name: ""
      });
    },
    removeValue: function(remIndex) {
      this.modelSynonym.synonyms.forEach(function(item, index, object) {
        if (item.id === remIndex) {
          object.splice(index, 1);
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let synonyms = [];
        this.$refs.f_synonyms.forEach(function(item) {
          synonyms.push(item.value);
        });

        this.$store
          .dispatch(POST_SYNONYMS, {
            term: this.$refs.f_term.value,
            synonyms: synonyms,
            synonym_type_id: this.model.synonym_type_id
          })
          .then(() => {
            this.dialog = false;
            this.$store.dispatch(REFRESH);
            this.$store.dispatch(PULL_SYNONYMS);
          });
      }
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.synonyms.errors,
      message: state => state.synonyms.message,
      hasMessage: state => state.synonyms.hasMessage,
      model: state => state.synonyms.model
    }),
    ...mapGetters(["modelSynonym"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
